// Markets Accordion Functionality
const accordionHeader = document.querySelectorAll(
  '.fp-classified-ad-markets-accordion__header',
)
const accordionContainer = document.querySelectorAll(
  '.fp-classified-ad-markets-accordion__container',
)

const initialStyles = () => {
  if (!accordionContainer.length) return

  accordionContainer.forEach(item => {
    item.classList.add('closed')
  })
  accordionContainer ? accordionContainer[0].classList.remove('closed') : null
  accordionContainer ? accordionContainer[0].classList.add('open') : null
}

const toggleAccordion = e => {
  accordionContainer.forEach(item => {
    item.classList.remove('open')
    item.classList.add('closed')
  })
  const container = e.currentTarget.parentElement
  container.classList.remove('closed')
  container.classList.add('open')
}

accordionHeader?.forEach(item => {
  item.addEventListener('click', toggleAccordion)
})

document.addEventListener('DOMContentLoaded', initialStyles)
