// Navigation Slider Functionality
const mainNavContainer = document.querySelector('.fp-main-nav')
const mainNavList = document.querySelector('.fp-main-nav__list')

if (mainNavContainer != null && mainNavList != null) {
  const navArrowContainer = document.querySelector(
    '.fp-main-nav__arrow-container',
  )
  const mainNavArrowLeft = document.querySelector('.fp-main-nav__left-arrow')
  const mainNavArrowRight = document.querySelector('.fp-main-nav__right-arrow')
  const mainNavArrowContainerWidth = 48
  let slideInitial = 0
  let slideAmount = 30

  // Check if the width of the list is greater than the navigation width

  if (
    mainNavList.getBoundingClientRect().width >
    mainNavContainer.getBoundingClientRect().width
  ) {
    // Set initial value to the navList
    mainNavContainer.style.setProperty('justify-content', 'flex-start')
    mainNavList.style.setProperty(
      'transform',
      `translate(${slideInitial}px, 0px)`,
    )
  }

  const calculateNavListWidth = () => {
    const mainNavContainerCoords = mainNavContainer.getBoundingClientRect()
    const mainNavListCoords = mainNavList.getBoundingClientRect()
    if (
      mainNavListCoords.width + mainNavArrowContainerWidth >
      mainNavContainerCoords.width
    ) {
      navArrowContainer &&
        navArrowContainer.style.setProperty('display', 'flex')
    } else {
      navArrowContainer &&
        navArrowContainer.style.setProperty('display', 'none')
      mainNavList.style.setProperty('transform', `translate(0px, 0px`)
    }
  }
  const slideNavLeft = e => {
    e.preventDefault()
    const mainNavListCoords = mainNavList.getBoundingClientRect()
    const mainNavContainerCoords = mainNavContainer.getBoundingClientRect()
    if (
      !(
        mainNavListCoords.right + mainNavArrowContainerWidth <
        mainNavContainerCoords.right
      )
    ) {
      slideInitial = slideInitial - slideAmount
      mainNavList.style.setProperty(
        'transform',
        `translate(${slideInitial}px, 0px`,
      )
    }
  }
  const slideNavRight = e => {
    e.preventDefault()
    if (mainNavList.style.transform !== 'translate(0px, 0px)') {
      slideInitial = slideInitial + slideAmount
      mainNavList.style.setProperty(
        'transform',
        `translate(${slideInitial}px, 0px`,
      )
    }
  }
  window.addEventListener('resize', calculateNavListWidth)
  calculateNavListWidth()
  mainNavArrowLeft.addEventListener('click', slideNavRight)
  mainNavArrowLeft.addEventListener('mousedown', slideNavRight)
  mainNavArrowRight.addEventListener('click', slideNavLeft)
  mainNavArrowRight.addEventListener('mousedown', slideNavLeft)
}
