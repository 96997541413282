const $ = require('jquery')

require('slick-carousel')

const slickOptions = $componentInstance => ({
  dots: true,
  prevArrow: $('.fp-carousel__prev-arrow', $componentInstance),
  nextArrow: $('.fp-carousel__next-arrow', $componentInstance),
  appendDots: $('.fp-carousel__dots', $componentInstance),
})

/**
 * Registers taboola tracking attributes removal on hidden slide elements.
 */
const registerTaboolaAttributesPerpetualRemoval = $currentSlides =>
  // setPosition - fired after resize, also resets attributes to original state
  $currentSlides.on('init setPosition', () =>
    $currentSlides.find('.slick-cloned a[internal]').each((i, clonedSlide) => {
      $(clonedSlide).removeAttr('data-tb-region-item')
    }),
  )

/**
 * Initializes slick for multiple component types.
 * Events must be set before initializing.
 */
$(function onDocumentReady() {
  // Multimedia Block
  const $multimediaBlocks = $('.fp-multimedia')
  $multimediaBlocks.each(function iterator() {
    const $currentMultimediaBlock = $(this)
    const $currentSlides = $(
      '.fp-multimedia-row__second',
      $currentMultimediaBlock,
    )

    const $progressContainer = $('.fp-carousel__dots', $currentMultimediaBlock)
    $progressContainer.addClass('fp-carousel__dots-gray')

    const renderProgress = (slideCount, currentSlide) => {
      // we get NaN as currentSlide in init.
      $progressContainer.text(`${currentSlide + 1 || 1} / ${slideCount}`)
    }

    $currentSlides.on('init', function initHandler(event, slick, currentSlide) {
      // Hide page numbers if no pagination is needed
      if (slick.slideCount <= slick.options.slidesToShow) {
        $progressContainer.addClass('slick-hidden').attr({
          'aria-disabled': 'true',
          tabindex: '-1',
        })
      } else {
        renderProgress(slick.slideCount, currentSlide)
      }
    })

    $currentSlides.on(
      'afterChange',
      function afterChangeHandler(event, slick, currentSlide) {
        renderProgress(slick.slideCount, currentSlide)
      },
    )

    registerTaboolaAttributesPerpetualRemoval($currentSlides)
    $currentSlides.slick({
      ...slickOptions($currentMultimediaBlock),
      dots: false,
      appendDots: false,
      rows: 1,
      slidesPerRow: 3,
      slidesToScroll: 1,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 1171,
          settings: {
            rows: 3,
            slidesPerRow: 1,
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 785,
          settings: {
            rows: 1,
            slidesPerRow: 1,
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
      ],
    })
  })

  // Listing Elements Block
  const $listingElementsBlocks = $('.fp-listing-teaser-elements')
  $listingElementsBlocks.each(function iterator() {
    const $currentlistingElementsBlock = $(this)
    const $currentSlides = $(
      '.fp-listing-teaser-elements__list',
      $currentlistingElementsBlock,
    )
    const rows = $currentSlides.data('carousel-slides-per-page') || 1
    registerTaboolaAttributesPerpetualRemoval($currentSlides)
    $currentSlides.slick({
      ...slickOptions($currentlistingElementsBlock),
      rows,
      slidesPerRow: 1,
      slidesToScroll: 1,
      slidesToShow: 1,
    })
  })

  // Gallery Carousel
  const $galleryCarousel = $('.fp-gallery-carousel')

  const trackEvent = (event, payload) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event,
      ...payload,
    })
  }
  const getDirection = (nextSlide, currentSlide, slick) => {
    let direction = null
    if (currentSlide === 0 && nextSlide === slick.$slides.length - 1) {
      direction = 'prev'
    } else if (
      nextSlide > currentSlide ||
      (currentSlide === slick.$slides.length - 1 && nextSlide === 0)
    ) {
      direction = 'next'
    } else {
      direction = 'prev'
    }
    return direction
  }

  $galleryCarousel.each(function iterator() {
    const $currentGalleryCarousel = $(this)
    const $currentSlides = $(
      '.fp-gallery-carousel__images',
      $currentGalleryCarousel,
    )
    const renderProgress = (slideCount, currentSlide) => {
      const $progressContainer = $(
        '.fp-gallery-carousel__controlls__progress',
        $currentGalleryCarousel,
      )
      $progressContainer.text(`${currentSlide || 1}/${slideCount}`)
    }
    $currentSlides.on('init', function afterChangeHandler(event, slick) {
      renderProgress(slick.slideCount)
    })
    $currentSlides.on(
      'beforeChange',
      (event, slick, currentSlide, nextSlide) => {
        const direction = getDirection(nextSlide, currentSlide, slick)
        const $nextSlide = $(slick.$slides[nextSlide])
        const galleryId = $nextSlide
          .closest('[data-li-gallery-id')
          .attr('data-li-gallery-id')
        const imageId =
          $('img', $nextSlide).attr('src') ||
          $('img', $nextSlide).attr('data-lazy')
        const imageTitle = $(
          '.fp-gallery-carousel__images--caption',
          $nextSlide,
        ).text()
        const imagePayload = {
          id: imageId,
          galleryId,
          title: imageTitle,
        }
        if (direction === 'next') {
          trackEvent('nextImage', {
            image: imagePayload,
          })
        } else if (direction === 'prev') {
          trackEvent('prevImage', {
            image: imagePayload,
          })
        }
      },
    )
    $currentSlides.on(
      'afterChange',
      function afterChangeHandler(event, slick, currentSlide) {
        renderProgress(slick.slideCount, currentSlide + 1)
        // Our own adaptive height
        const $currentSlide = $('.slick-slide.slick-current', $currentSlides)
        const $carouselWrapper = $currentSlide.closest('.slick-list')
        const $imageWrapper = $(
          '.fp-gallery-carousel__images--article',
          $currentSlide,
        )
        $('img', $imageWrapper)
          .one('load', function onLoad() {
            // NOTE: We have the timeout to wait for the elements
            // until they cascade the sizing
            setTimeout(() => {
              const height = $imageWrapper.height()
              if (height) {
                $carouselWrapper.css('transition', 'height 500ms ease 0s')
                $carouselWrapper.css('height', height)
              }
            }, 100)
          })
          .each(function iteratee() {
            // in case the image is cached
            if (this.complete) {
              $(this).trigger('load')
            }
          })
      },
    )
    registerTaboolaAttributesPerpetualRemoval($currentSlides)
    $currentSlides.slick({
      ...slickOptions($currentGalleryCarousel),
      dots: false,
      appendDots: false,
      lazyLoad: 'ondemand',
      rows: 1,
      slidesPerRow: 1,
      slidesToScroll: 1,
      slidesToShow: 1,
    })
  })

  const $galleryArticleCarousel = $('.fp-gallery')
  $galleryArticleCarousel.each(function iterator() {
    const $galleryAd = $('.gallery-ad')
    const $currentGalleryCarousel = $(this)
    const $currentSlides = $('ul', $currentGalleryCarousel)
    const renderProgress = (slideCount, currentSlide) => {
      const $progressContainer = $(
        '.fp-gallery-carousel__controlls__progress',
        $currentGalleryCarousel,
      )
      $progressContainer.text(`${currentSlide || 1} von ${slideCount} Fotos`)
    }
    $currentSlides.on('init', (event, slick) => {
      let slickObj = slick
      if (slickObj.$slides === null) {
        // Backup - because sometimes most slickObj attributes are null
        slickObj = $currentSlides.slick('getSlick')
      }

      $galleryAd.detach()

      function appendAd(slideNumber) {
        const slideIndex = slideNumber - 1
        if (
          slickObj.$slides !== null &&
          $(slickObj.$slides[slideIndex]).length > 0
        ) {
          const $currentSlide = $(slickObj.$slides[slideIndex])
          const alreadyHaveAds = $('.gallery-ad', $currentSlide).length > 0
          if (!alreadyHaveAds) {
            $galleryAd.appendTo($currentSlide)
            const $adCloseButton = $('.gallery-ad__close', $galleryAd)
            $adCloseButton.on('click', event => {
              event.preventDefault()
              $currentGalleryCarousel.removeClass('fp-gallery__disabled')
              $galleryAd.detach()
              $adCloseButton.off()
              appendAd(slideNumber + 5)
            })
          }
        }
      }
      appendAd(5)

      renderProgress(slickObj.slideCount)
    })

    $currentSlides.on(
      'beforeChange',
      (event, slick, currentSlide, nextSlide) => {
        const direction = getDirection(nextSlide, currentSlide, slick)
        const $nextSlide = $(slick.$slides[nextSlide])
        const galleryId = $('.fp-article').attr('data-li-gallery-id')
        const imageId =
          $('img', $nextSlide).attr('src') ||
          $('img', $nextSlide).attr('data-lazy')
        const imageTitle = $('.fp-gallery__info--caption', $nextSlide).text()
        const imagePayload = {
          id: imageId,
          galleryId,
          title: imageTitle,
        }
        if (direction === 'next') {
          trackEvent('nextImage', {
            image: imagePayload,
          })
        } else if (direction === 'prev') {
          trackEvent('prevImage', {
            image: imagePayload,
          })
        }
      },
    )
    $currentSlides.on(
      'afterChange',
      function afterChangeHandler(event, slick, currentSlide) {
        renderProgress(slick.slideCount, currentSlide + 1)
        if ((currentSlide + 1) % 5 === 0) {
          const $activeSlide = $('.slick-active')
          const $adSlot = $activeSlide.find('.gtm-ad-slot', '.gallery-ad')
          const hasAdLoaded =
            $adSlot.length > 0 && $adSlot.children('div').length > 0
          if (hasAdLoaded) {
            $currentGalleryCarousel.addClass('fp-gallery__disabled')
          }
          if ($adSlot && $adSlot.attr('data-ad-position')) {
            const adSlotId = $adSlot.attr('data-ad-position')
            trackEvent('reloadAd', {
              adPosition: adSlotId,
            })
          }
        }
      },
    )
    registerTaboolaAttributesPerpetualRemoval($currentSlides)
    $currentSlides.slick({
      ...slickOptions($currentGalleryCarousel),
      dots: false,
      appendDots: false,
      lazyLoad: 'ondemand',
      rows: 1,
      slidesPerRow: 1,
      slidesToScroll: 1,
      slidesToShow: 1,
    })
  })
})
