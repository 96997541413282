/**
 * The add_taboola_regions.js[1] enricher assumes all elements are responsive and thus
 * adds duplicate tracking attributes in non-responsive elements,
 * thus confusing the taboola tracking metrics.
 * See this[2] comment in the original issue for more details.
 *
 * The issue is with the 'vertical_teaser element (Teaser 2/3 Block),
 * which is non-responsive and has a desktop and mobile variation.
 * Because of this, we can only remove the extra attributes client-side (we depend on the viewport).
 *
 * Another issue is with the Most Read Articles block.
 * The image is of the first article and also contains a link with taboola attributes
 * so there is a duplicate tracked link for the first article.
 *
 * [1] asc-delivery-web/app/content/enrichers/basic-enrichers/enrichers/add_taboola_regions.js
 * [2] https://jira.extranet.netcetera.biz/jira/browse/NCA510FPAS-1279?focusedCommentId=667812&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-667812
 */
window.addEventListener('DOMContentLoaded', () => {
  // querySelectorAll is slow, so we query only once
  const verticalTeaserAnchors = {
    desktop: document.querySelectorAll(
      '.fp-vertical-article__desktop a[internal]',
    ),
    mobile: document.querySelectorAll(
      '.fp-vertical-article__mobile a[internal]',
    ),
  }

  adjustVerticalTeaserTaboola(verticalTeaserAnchors)
  window.addEventListener('resize', () =>
    adjustVerticalTeaserTaboola(verticalTeaserAnchors),
  )

  adjustMostReadArticlesTaboola()
})

const adjustVerticalTeaserTaboola = anchors => {
  const isMobileView = window.innerWidth <= mobileMaxWidth

  anchors.desktop.forEach(
    isMobileView ? removeTaboolaAttribute : setTaboolaAttribute,
  )
  anchors.mobile.forEach(
    isMobileView ? setTaboolaAttribute : removeTaboolaAttribute,
  )
}

const adjustMostReadArticlesTaboola = () =>
  document
    .querySelectorAll(
      'section.fp-most-read-articles__container a[internal] img',
    )
    .forEach(mostReadArticlesBlockImage =>
      removeTaboolaAttribute(mostReadArticlesBlockImage.closest('a')),
    )

/* HELPER FUNCTIONS */

const setTaboolaAttribute = anchor =>
  anchor.setAttribute('data-tb-region-item', '')

const removeTaboolaAttribute = anchor =>
  anchor.removeAttribute('data-tb-region-item')

/*
  As defined in asc-base-design:
    // Responsive variables
    //   $mobile: 785px;
*/
const mobileMaxWidth = 785
