const {
  ARTICLE_ID_ATTR,
} = require('../app/content/enrichers/basic-enrichers/enrichers/add_article_id')

const $ = require('jquery')

const BATCH_SIZE = 5
const $loadMoreButtons = $('.js-fp-load-more-btn')
const $loadMoreTopicArticlesButton = $(
  '.js-fp-topics__teasers__button--load-more',
)

// Attach load more behavior to "load more" button on topic pages
$loadMoreTopicArticlesButton.on(
  'click',
  async function loadMoreTopicArticles() {
    const topic = $(this).attr('data-topic')
    const sectionElement = $(this).siblings('section')

    let excludeIds = getExistingArticleIds()
    try {
      const response = await fetchMoreArticlesForTopic(topic, excludeIds)
      if (response) {
        // Wrap each teaser article in <aside> element and append it in sectionElement
        response.forEach(element => {
          sectionElement.append($('<aside>').append(element))
        })
      }

      // When all articles returned, leave message for user and remove button
      if (!response || response.length === 0) {
        $(this).before(
          "<p class='fp-topics__teasers__no-results'>Keine weiteren Artikel mehr vorhanden.</p>",
        )
        $(this).remove()
      }
    } catch (err) {
      console.error(err)
    }
  },
)

// Add class for styling purposes
$loadMoreButtons.each(function addStylingClass() {
  const $buttonContainer = $(this).parent()
  const $container = $buttonContainer.parent()

  $container.addClass('has-load-more')
})

// Attach the load more behavior to all "load more" buttons
$loadMoreButtons.on(
  'click',
  async function loadMoreArticles(batch_size = BATCH_SIZE) {
    const $buttonContainer = $(this).parent()
    const $container = $buttonContainer.parent()
    const $firstArticle = $container.find('article').first()
    const $articlesContainer = $firstArticle.parent()

    // Read layout to use for the rendering of more articles
    const layout = $(this).attr('data-layout')

    // Read list ID associated with single teaser from first article
    const listId = $firstArticle.attr('data-list-id')
    const categoryIds = $firstArticle.attr('data-category-ids')
    const subcategoryIds = $firstArticle.attr('data-subcategory-ids')

    // Fetch more articles using this list ID
    const articles = await fetchMoreArticles(
      layout,
      listId,
      categoryIds,
      subcategoryIds,
    )

    // If some returned, append them to associated container
    if (articles) {
      $articlesContainer.append(articles)
    }

    // When all articles returned, leave message for user and remove button
    if (!articles || articles.length < batch_size) {
      $buttonContainer.before(
        "<p class='fp-load-more-text-done'>Keine weiteren Artikel mehr vorhanden.</p>",
      )
      $buttonContainer.remove()
    }
  },
)

const fetchMoreArticlesForTopic = (topic, excludeIds) => {
  return $.ajax({
    url: '/api/load-topic-articles',
    type: 'PUT',
    data: {
      topic,
      excludeIds,
    },
  }).catch(err => {
    console.error(err)
  })
}

const fetchMoreArticles = (
  layout,
  listId,
  categoryIds,
  subcategoryIds,
  count = 5,
) => {
  return $.ajax({
    url: `/api/load-more`,
    type: 'PUT', // Using a PUT request to avoid reaching URL size limit with GET requests
    data: {
      loadedArticleIds: getExistingArticleIds(),
      layout,
      count,
      listId,
      categoryIds,
      subcategoryIds,
    },
  }).catch(err => {
    console.error(err.responseText)
  })
}

const getExistingArticleIds = () => {
  const articlesIds = $('article')
    .map((_, el) => el.getAttribute(ARTICLE_ID_ATTR))
    .get()

  // Remove duplicates (due to mobile markup)
  return [...new Set(articlesIds)]
}
