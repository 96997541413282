const sideMenuContainer = document.querySelector('.fp-sidebar-menu')
const sideMenuOverlay = document.querySelector('.fp-sidebar-menu__overlay')
const openSideMenuButton = document.querySelectorAll(
  '.fp-main-header__menu-search-wrapper--burger',
)
const headerSearchIcon = document.querySelector(
  '.fp-main-header__menu-search-wrapper--search',
)
const openSideMenuButtonResponsive = document.querySelector(
  '.fp-header-responsive__top .fp-main-header__menu-search-wrapper--burger',
)
const closeSideMenuButton = document.querySelector(
  '.fp-sidebar-menu__header--close',
)

const openSideMenu = () => {
  sideMenuContainer.classList.add('open')
  sideMenuOverlay.classList.add('open')
  document.body.classList.add('overflow-hidden')
}
const closeSideMenu = () => {
  sideMenuContainer.classList.remove('open')
  sideMenuOverlay.classList.remove('open')
  document.body.classList.remove('overflow-hidden')
}
const escapeSideMenu = e => {
  if (e.key === 'Escape') {
    sideMenuContainer.classList.remove('open')
    sideMenuOverlay.classList.remove('open')
    document.body.classList.remove('overflow-hidden')
  }
}

openSideMenuButton &&
  openSideMenuButton.forEach(button =>
    button.addEventListener('click', openSideMenu),
  )

// Enable keyboard accessibility
openSideMenuButton &&
  openSideMenuButton.forEach(button =>
    button.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        e.preventDefault()
        openSideMenu()
      }
    }),
  )

openSideMenuButtonResponsive &&
  openSideMenuButtonResponsive.addEventListener('click', openSideMenu)

closeSideMenuButton &&
  closeSideMenuButton.addEventListener('click', closeSideMenu)

document.body.addEventListener('keyup', escapeSideMenu)

sideMenuOverlay && sideMenuOverlay.addEventListener('click', closeSideMenu)
