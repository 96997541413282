function getCookie(name) {
  if (name instanceof RegExp) return getCookieFromRegex(name)
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

/**
 * Tries to find the value of a cookie whose name matches a given regex
 * @param {Object} regex Regular expression which the cookie name must match
 * @returns the value of a potentially found cookie
 */
function getCookieFromRegex(regex) {
  if (!regex.source) return
  const value = `; ${document.cookie}`
  const enrichedRegex = new RegExp('; ' + regex.source + '=')
  const parts = value.split(enrichedRegex)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

function setCookie(name, value, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${name}=${value};${expires};path=/`
}

function removeCookie(name, domain = false) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${
    domain ? `domain=${findDomain()};` : ''
  }path=/;`
}

function findDomain() {
  const domainArray = window.location.host.split('.')
  domainArray.shift()
  return domainArray.join('.')
}

module.exports = {
  getCookie,
  setCookie,
  removeCookie,
}
