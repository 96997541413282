const $ = require('jquery')

const newsletterSubmit = $('.fp-newsletter__opt-in-modul__submit')

newsletterSubmit.on('click', event => {
  const $element = $(event.currentTarget)
  // email should be in the previous element of the button
  const emailInputValue = $element.prev().val()
  const messageField = $element
    .parent()
    .parent()
    .find('.fp-newsletter__opt-in-modul__message')
  const newsletterTypeId = $element
    .parentsUntil('.fp-newsletter__opt-in-modul__wrapper')
    .find('#newsletterTypeId')[0].innerHTML
  if (validateEmail(emailInputValue)) {
    const data = {
      email: emailInputValue,
      newsletterTypeId,
    }
    $.ajax({
      url: '/newsletter/subscription',
      type: 'POST',
      data,
      success() {
        addSuccessClass(messageField)
        // Translation: The request has been sent successfully. Please check your e-mail.
        messageField.text(
          'Ihre Anfrage wurde erfolgreich verschickt. Bitte überprüfen Sie Ihren Posteingang für die Bestätigung.',
        )
      },
      error() {
        addErrorClass(messageField)
        // Translation: There's been an error while processing the request. Please try again later.
        messageField.text(
          'Bei der Bearbeitung Ihrer Anfrage ist ein Problem aufgetreten. Bitte versuchen Sie es später erneut.',
        )
      },
    })
  } else {
    addErrorClass(messageField)
    // Translation: Please enter a valid e-mail address
    messageField.text('Geben Sie bitte eine gültige E-Mail Adresse ein.')
  }
})

const addErrorClass = messageField => {
  if (!messageField.hasClass('fp-newsletter__opt-in-modul__message-error')) {
    messageField.removeClass('fp-newsletter__opt-in-modul__message-success')
    messageField.addClass('fp-newsletter__opt-in-modul__message-error')
  }
}

const addSuccessClass = messageField => {
  if (!messageField.hasClass('fp-newsletter__opt-in-modul__message-success')) {
    messageField.removeClass('fp-newsletter__opt-in-modul__message-error')
    messageField.addClass('fp-newsletter__opt-in-modul__message-success')
  }
}

const validateEmail = email => {
  if (
    email &&
    email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  ) {
    return true
  }
  return false
}
