const $ = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
  const alphabetButtons = document.getElementById('alphabetFilterButtons')

  if (!alphabetButtons || alphabetButtons.children.length === 0) {
    return
  }

  var clickedLetter
  var letterContainer
  var selectedLetterEl = $('#fpAuthorsListSelectedLetter')
  const clearFilterBtnEl = $('#clearFilterButton')
  const noAuthorsFoundByFilterEl = $('#noAuthorsFoundMessage')
  const authors =
    $('#authorsList')
      .data('fp-authors-surnames')
      ?.map((surname, index) => ({
        surname,
        index,
      })) || []
  const filterAuthors = event => {
    letterContainer = $(event.target)

    // Don't filter the array if the user clicked the same letter two or more times in a row
    if (clickedLetter === event.target.innerText) {
      return
    }

    if (!visible(clearFilterBtnEl)) {
      clearFilterBtnEl.show()
    }

    // Add different accent color to the clicked letter circle
    letterContainer
      .addClass('clicked')
      .siblings('span.clicked')
      .removeClass('clicked')

    clickedLetter = event.target.innerText

    selectedLetterEl.html(clickedLetter)

    // Filter the authors by their surname and clicked letter
    const authorsToKeep = authors.filter(
      x => x.surname.charAt(0).toUpperCase() === clickedLetter,
    )
    const authorsToHide = authors.filter(
      x => x.surname.charAt(0).toUpperCase() !== clickedLetter,
    )

    authorsToKeep.length === 0
      ? noAuthorsFoundByFilterEl.show()
      : noAuthorsFoundByFilterEl.hide()

    authorsToKeep.forEach(author => $(`#author-card-${author.index}`).show())
    authorsToHide.forEach(author => $(`#author-card-${author.index}`).hide())
  }

  const clearFilter = () => {
    clickedLetter = ''
    selectedLetterEl.html(clickedLetter)
    letterContainer.removeClass('clicked')
    if (visible(noAuthorsFoundByFilterEl)) {
      noAuthorsFoundByFilterEl.hide()
    }
    if (visible(clearFilterBtnEl)) {
      clearFilterBtnEl.hide()
    }
    if (authors) {
      authors.forEach((_author, index) => {
        const authorCardEl = $(`#author-card-${index}`)
        authorCardEl.show()
      })
    }
  }

  const visible = el => {
    return el.is(':visible')
  }

  Array.from(alphabetButtons.children).forEach(btn => {
    btn.addEventListener('click', filterAuthors)
  })

  clearFilterBtnEl.on('click', clearFilter)
})
