/**
 * Registers an on input event listener which will search for topics
 * which contain that word.
 */

const $ = require('jquery')

const $searchBar = $('.fp-topics__search__input')
const $searchResultsWrapper = $('.js-topics-search-results')
const $searchResults = $('.js-topics-search-results li')

$searchBar.on('input', event => {
  const word = event.currentTarget.value
  // If the search is cleared, clear the results
  if (word === '') {
    $searchResultsWrapper.css('display', 'none')
    $searchResults.each((index, el) => {
      $(el).html('')
    })
    // Else if a new letter to the search is added, search for topics and present them
  } else {
    $.get(`/api/topics/search?word=${word}`).then(resp => {
      if (resp.length > 0) {
        $searchResultsWrapper.css('display', 'block')
        $searchResults.each((index, el) => {
          // This check is to limit the shown results to the number of list items we have
          // in our template, currently 10.
          if (resp[index]) {
            const regex = new RegExp(word, 'i')
            const [topicName, topicUrl] = resp[index]
            const respHtml = topicName.replace(
              regex,
              match => `<mark>${match}</mark>`,
            )
            $(el).html(`<a href="${topicUrl}" >${respHtml}</a>`)
          } else {
            $(el).html('')
          }
        })
      } else {
        $searchResultsWrapper.css('display', 'none')
      }
    })
  }
})
