// Classes
const HIDE_CLASS = 'fp-header__sticky-hide'
const SHOW_CLASS = 'fp-header__sticky-show'

// Elements
const header = document.querySelector('.fp-header')
const stickyHeader = document.querySelector('.fp-header__sticky')

// Sizes
let headerWidth = header.getBoundingClientRect().width
let headerHeight = header.getBoundingClientRect().height

// Initialize scroll value
let lastScroll = 0

const showHeader = () => {
  stickyHeader.classList.remove(HIDE_CLASS)
  stickyHeader.classList.add(SHOW_CLASS)
}
const hideHeader = () => {
  stickyHeader.classList.remove(SHOW_CLASS)
  stickyHeader.classList.add(HIDE_CLASS)
}

const updateSizes = () => {
  const { width, height } = header.getBoundingClientRect()

  // Update header sizes
  headerWidth = width
  headerHeight = height

  // Update sticky width based on current window size
  stickyHeader.style.setProperty('width', `${headerWidth + 2}px`)
}

// Stick header to top
const handleStickiness = () => {
  const currentScroll = window.pageYOffset

  const isPastHeader = currentScroll > headerHeight
  const hasScrolledDown = currentScroll > lastScroll
  const hasScrolledUp = currentScroll < lastScroll

  if (hasScrolledDown && isPastHeader) {
    showHeader()
  } else if (hasScrolledUp && !isPastHeader) {
    hideHeader()
  }

  // Update scroll value
  lastScroll = currentScroll
}

window.addEventListener('load', updateSizes)
window.addEventListener('resize', updateSizes)
window.addEventListener('scroll', handleStickiness)
