// Navigation Slider Functionality
const navContainer = document.querySelector('.fp-top-nav')
const navList = document.querySelector('.fp-top-nav__list')

if (navContainer != null && navList != null) {
  const navArrowContainer = document.querySelector(
    '.fp-top-nav__arrow-container',
  )
  const navArrowLeft = document.querySelector('.fp-top-nav__left-arrow')
  const navArrowRight = document.querySelector('.fp-top-nav__right-arrow')
  const navArrowContainerWidth = 48
  let slideInitial = 0
  let slideAmount = 30

  // Set initial value to the navList
  navList.style.setProperty('transform', `translate(${slideInitial}px, 0px)`)
  const calculateNavListWidth = () => {
    const navContainerCoords = navContainer.getBoundingClientRect()
    const navListCoords = navList.getBoundingClientRect()
    if (
      navListCoords.width + navArrowContainerWidth >
      navContainerCoords.width
    ) {
      navArrowContainer &&
        navArrowContainer.style.setProperty('display', 'flex')
    } else {
      navArrowContainer &&
        navArrowContainer.style.setProperty('display', 'none')
      navList.style.setProperty('transform', `translate(0px, 0px)`)
    }

    if (slideInitial >= 0) {
      navArrowLeft.style.setProperty('display', 'none')
    } else {
      navArrowLeft.style.setProperty('display', 'block')
    }

    if (slideInitial <= calculateEndInitial()) {
      navArrowRight.style.setProperty('display', 'none')
    } else {
      navArrowRight.style.setProperty('display', 'block')
    }
  }

  const calculateEndInitial = () => {
    const navListCoords = navList.getBoundingClientRect()
    const navContainerCoords = navContainer.getBoundingClientRect()

    return navContainerCoords.width - navListCoords.width
  }

  const findNavItemsPositions = () => {
    const items = navList.querySelectorAll('li')
    const navContainerCoords = navContainer.getBoundingClientRect()
    let firstVisibleIndex = -1
    let lastVisibleIndex = -1
    let nextItemDistance = slideAmount
    let prevItemDistance = slideAmount

    items.forEach((item, index) => {
      const itemCoords = item.getBoundingClientRect()
      const isVisible =
        itemCoords.right <= navContainerCoords.right &&
        itemCoords.left >= navContainerCoords.left

      if (isVisible) {
        lastVisibleIndex = index
        if (firstVisibleIndex === -1) {
          firstVisibleIndex = index
        }
      }
    })

    // Calculate next item distance
    const nextItem =
      lastVisibleIndex >= 0 && lastVisibleIndex < items.length - 1
        ? items[lastVisibleIndex + 1]
        : null
    if (nextItem) {
      nextItemDistance =
        nextItem.getBoundingClientRect().right - navContainerCoords.right
    }

    // Calculate previous item distance
    const prevItem = firstVisibleIndex > 0 ? items[firstVisibleIndex - 1] : null
    if (prevItem) {
      const navContainerWidth = navContainer.getBoundingClientRect().width
      prevItemDistance =
        navContainerCoords.right -
        navContainerWidth -
        prevItem.getBoundingClientRect().left
    }

    return {
      nextItemDistance,
      prevItemDistance,
    }
  }

  const slideNavLeft = e => {
    e.preventDefault()
    const end = calculateEndInitial()
    const { nextItemDistance } = findNavItemsPositions()

    slideInitial = slideInitial - nextItemDistance
    if (slideInitial <= end) {
      slideInitial = end
    }
    navList.style.setProperty('transform', `translate(${slideInitial}px, 0px`)

    calculateNavListWidth()
  }

  const slideNavRight = e => {
    e.preventDefault()

    if (slideInitial <= 0) {
      const { prevItemDistance } = findNavItemsPositions()
      slideInitial = slideInitial + prevItemDistance
      if (slideInitial > 0) {
        slideInitial = 0
      }

      navList.style.setProperty(
        'transform',
        `translate(${slideInitial}px, 0px)`,
      )
    }

    calculateNavListWidth()
  }

  window.addEventListener('resize', calculateNavListWidth)
  calculateNavListWidth()
  navArrowLeft.addEventListener('click', slideNavRight)
  navArrowLeft.addEventListener('mousedown', slideNavRight)
  navArrowRight.addEventListener('click', slideNavLeft)
  navArrowRight.addEventListener('mousedown', slideNavLeft)
}
