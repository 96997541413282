const formattedDate = new Date().toLocaleString('de-DE', {
  weekday: 'long',
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})
// example outputs:
//   Dienstag, 01.03.2022
//   Donnerstag, 24.03.2022
//   new Date(new Date().getTime() + DAYS_OFFSET * 24 * 60 * 60 * 1000).toLocaleString(...)

const headerDateRender = document.querySelectorAll(
  '.fp-main-header__current-date span',
)

headerDateRender.forEach(render => {
  render.innerHTML = formattedDate
  render.setAttribute('title', formattedDate)
  render.setAttribute('alt', formattedDate)
})
