const { probeForTcfApi } = require('./helpers/probe_for_tcf_api')

probeForTcfApi(() => {
  // initial consent check
  window.__tcfapi('addEventListener', 2, (tcData, success) => {
    if (!success) return

    if (
      tcData.eventStatus === 'tcloaded' ||
      tcData.eventStatus === 'useractioncomplete'
    ) {
      checkSocialMediaConsents()

      window.__tcfapi('removeEventListener', 2, () => {}, tcData.listenerId)
    }
  })

  // add actions and act on consent
  activateSocialMediaPlaceholderActions()
})

function checkSocialMediaConsents() {
  window.__tcfapi('getTCData', 2, data => {
    const enabledFor = {}
    const consentedVendors = data.customVendorConsents || {}
    const socialMediaPlaceholders = [].slice.call(
      document.getElementsByClassName('social-media-placeholder'),
    )
    for (const placeholder of socialMediaPlaceholders) {
      const vendorId = placeholder.getAttribute('data-vendor-id')
      const vendorHandle = placeholder.getAttribute('data-vendor-handle')
      const vendorGrant = consentedVendors[vendorId]
      if (vendorGrant && !enabledFor[vendorHandle]) {
        enableSocialMediaByHandle(vendorHandle)
        enabledFor[vendorHandle] = true
      }
    }
  })
}

function activateSocialMediaPlaceholderActions() {
  const socialMediaPlaceholders = [].slice.call(
    document.getElementsByClassName('social-media-placeholder'),
  )
  for (const placeholder of socialMediaPlaceholders) {
    const vendorId = placeholder.getAttribute('data-vendor-id')
    const vendorHandle = placeholder.getAttribute('data-vendor-handle')

    const actionElement = placeholder.getElementsByClassName(
      `placeholder-trigger-${vendorHandle}`,
    )[0]
    if (actionElement) {
      actionElement.addEventListener('click', () => {
        window.__cmp('setVendorConsent', [vendorId, 1], (data, success) => {
          if (success) {
            enableSocialMediaByHandle(vendorHandle)
          }
        })
      })
    }
  }
}

function enableSocialMediaByHandle(vendorHandle) {
  if (vendorHandle === 'instagram') {
    enableInstagramPosts()
  } else if (vendorHandle === 'twitter') {
    enableTwitterPosts()
  } else if (vendorHandle === 'tiktok') {
    enableTiktokPosts()
  } else if (vendorHandle === 'facebook') {
    enableFacebookPosts()
  } else if (vendorHandle === 'youtube') {
    enableYoutubePosts()
  } else if (vendorHandle === 'spotify') {
    enableSpotifyPosts()
  }
}

function enableInstagramPosts() {
  if (!document.getElementById('instagramScript')) {
    makeEmbedsVisible('instagram-media')
    removePlaceholders('instagram')
    var e = document.createElement('script')
    e.id = 'instagramScript'
    e.type = 'text/javascript'
    e.async = true
    e.src = '//www.instagram.com/embed.js'
    document.body.appendChild(e)
  }
}

function enableTwitterPosts() {
  if (!document.getElementById('twitterScript')) {
    makeEmbedsVisible('twitter-tweet')
    removePlaceholders('twitter')
    var e = document.createElement('script')
    e.id = 'twitterScript'
    e.type = 'text/javascript'
    e.async = true
    e.src = 'https://platform.twitter.com/widgets.js'
    document.body.appendChild(e)
  }
}

function enableTiktokPosts() {
  if (!document.getElementById('tiktokScript')) {
    makeEmbedsVisible('tiktok-embed')
    removePlaceholders('tiktok')
    var e = document.createElement('script')
    e.id = 'tiktokScript'
    e.type = 'text/javascript'
    e.async = true
    e.src = 'https://www.tiktok.com/embed.js'
    document.body.appendChild(e)
  }
}

function enableFacebookPosts() {
  const facebookScriptUrl = '//www.facebook.com/plugins'
  for (const el of document.getElementsByTagName('iframe')) {
    if (
      el.getAttribute('data-src') &&
      el.getAttribute('data-src').includes(facebookScriptUrl)
    ) {
      el.setAttribute('src', el.getAttribute('data-src'))
      el.classList.remove('hide-embed')
      fixSpacing(el)
    }
  }
  removePlaceholders('facebook')
}

function enableYoutubePosts() {
  const youtubeScriptUrl = '//www.youtube.com/embed'
  for (const el of document.getElementsByTagName('iframe')) {
    if (
      el.getAttribute('data-src') &&
      el.getAttribute('data-src').includes(youtubeScriptUrl)
    ) {
      el.setAttribute('src', el.getAttribute('data-src'))
      el.classList.remove('hide-embed')
      fixSpacing(el)
    }
  }
  removePlaceholders('youtube')
}

function enableSpotifyPosts() {
  const spotifyScriptUrl = '//open.spotify.com/embed'
  for (const el of document.getElementsByTagName('iframe')) {
    if (
      el.getAttribute('data-src') &&
      el.getAttribute('data-src').includes(spotifyScriptUrl)
    ) {
      el.setAttribute('src', el.getAttribute('data-src'))
      el.classList.remove('hide-embed')
      fixSpacing(el)
    }
  }
  removePlaceholders('spotify')
}

function makeEmbedsVisible(className) {
  const embeds = document.getElementsByClassName(className)
  for (const embed of embeds) {
    embed.classList.remove('hide-embed')
  }
}

function removePlaceholders(vendor) {
  const placeholders = document.getElementsByClassName(
    `social-media-placeholder-${vendor}`,
  )
  let placeholder = null
  while ((placeholder = placeholders[0])) {
    placeholder.remove()
  }
}

function fixSpacing(el) {
  const parent = el.parentNode
  parent.style.height = parent.getAttribute('data-height')
  parent.style.paddingBottom = parent.getAttribute('data-padding-bottom')
  parent.style.overflow = parent.getAttribute('data-overflow')
}
