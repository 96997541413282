const $ = require('jquery')

const headerSearchContainer = document.querySelector('.fp-main-header-search')
const headerSearchInput = document.querySelector('#headerSearchInput')
const headerSearchButton = document.querySelector('#headerSearchButton')

const openHeaderSearchButton = document.querySelectorAll(
  '.fp-main-header__menu-search-wrapper--search',
)

const closeHeaderSearchButton = document.querySelector(
  '.fp-main-header-search__close',
)

const sidebarSearchInput = document.querySelector('#sidebarSearchInput')
const sidebarSearchButton = document.querySelector('#sidebarSearchButton')
const sidebarSearchClose = document.querySelector(
  '.fp-sidebar-menu__search__close',
)

const dedicatedSearchInput = document.querySelector('#dedicatedSearchInput')
const dedicatedSearchButton = document.querySelector('#dedicatedSearchButton')
const dedicatedSearchClose = document.querySelector(
  '.fp-dedicated-search__search__close',
)
const dedicatedSearchLoadMoreButton = document.querySelector(
  '.js-dedicated-search-load-more',
)

const baseSearchUrl = '/api/public/search'
const constructSearchUri = (query, opts) => {
  let url = `${baseSearchUrl}?search=${query}`

  if (!opts) return url

  const { mode, page, where } = opts

  if (where) {
    url += `&searchtype=${opts.where}`
  }

  if (mode === 'partial') {
    url += `&mode=partial&page=${page}`
  }

  return url
}

const dropdownResults = document.getElementById('dropdownResults')
const headerSearchDropdown = document.querySelector(
  '.fp-main-header-search__dropdown',
)
const headerSearchOverlay = document.querySelector(
  '.fp-main-header-search__overlay',
)

const openHeaderSearch = () => {
  headerSearchOverlay.classList.add('open')
  headerSearchContainer.classList.add('open')
  headerSearchInput.focus()
  document.body.classList.add('overflow-hidden')
}

const closeHeaderSearch = () => {
  headerSearchContainer.classList.remove('open')
  headerSearchInput.value = ''
  dropdownResults.innerHTML = ''
  headerSearchDropdown.classList.remove('open')
  headerSearchOverlay.classList.remove('open')
  document.body.classList.remove('overflow-hidden')
}

const escapeHeaderSearch = e => {
  if (e.key === 'Escape') {
    headerSearchOverlay.classList.remove('open')
    headerSearchContainer.classList.remove('open')
    headerSearchInput.value = ''
    dropdownResults.innerHTML = ''
    headerSearchDropdown.classList.remove('open')
    document.body.classList.remove('overflow-hidden')
  }
}

const openSearchPage = (searchValue, where) => {
  const query = encodeURIComponent(searchValue)
  window.location.href = constructSearchUri(query, { where })
}

const enterHeaderSearch = e => {
  if (e.key === 'Enter' && headerSearchInput.value) {
    openSearchPage(headerSearchInput.value, 'headersearch')
  } else if (!headerSearchInput.value) {
    dropdownResults.innerHTML = ''
    headerSearchDropdown.classList.remove('open')
  }
}

const handleSidebarSearch = e => {
  let searchValue = sidebarSearchInput.value

  if (e.key === 'Enter' && !!searchValue) {
    openSearchPage(searchValue, 'sidebarsearch')
    sidebarSearchInput.value = ''
  }

  if (searchValue.length > 0) {
    sidebarSearchClose.classList.add('visible')
  } else {
    sidebarSearchInput.value = ''
    sidebarSearchClose.classList.remove('visible')
  }

  sidebarSearchClose.addEventListener('click', () => {
    sidebarSearchInput.value = ''
    sidebarSearchClose.classList.remove('visible')
  })
}

const handleSidebarSearchButton = () => {
  if (sidebarSearchInput.value) {
    openSearchPage(sidebarSearchInput.value, 'sidebarsearch')
  } else {
    sidebarSearchInput.focus()
  }
  sidebarSearchInput.value = ''
}

const openSearchResultsDropdown = () => {
  // FIXME: NO-OP
}

const handleHeaderSearchButton = () => {
  if (headerSearchInput.value) {
    openSearchResultsDropdown()
  } else {
    headerSearchInput.focus()
  }
  headerSearchInput.value = ''
}

const handleDedicatedSearch = e => {
  let searchValue = dedicatedSearchInput.value

  if (e.key === 'Enter' && !!searchValue) {
    openSearchPage(searchValue, 'dedicatedsearch')
    dedicatedSearchInput.value = ''
  }

  if (searchValue.length > 0) {
    dedicatedSearchClose.classList.add('visible')
  } else {
    dedicatedSearchInput.value = ''
    dedicatedSearchClose.classList.remove('visible')
  }

  dedicatedSearchClose.addEventListener('click', () => {
    dedicatedSearchInput.value = ''
    dedicatedSearchClose.classList.remove('visible')
  })
}

const handleDedicatedSearchButton = () => {
  if (dedicatedSearchInput.value) {
    openSearchPage(dedicatedSearchInput.value, 'dedicatedsearch')
  } else {
    dedicatedSearchInput.focus()
  }
  dedicatedSearchInput.value = ''
}

const checkCanLoadMore = () => {
  const canLoadMoreClass = 'fp-dedicated-search__load-more--has-more'
  const $resultsWrapper = $('[data-dedicated-search-results]')
  const $wrapper = $(dedicatedSearchLoadMoreButton).closest(
    '[data-dedicated-search-load-more]',
  )
  const pageSize = Number($wrapper.data('page-size'))
  const totalResults = Number($wrapper.data('total-results'))
  const shownResults = $resultsWrapper.children().length
  if (totalResults > shownResults && shownResults >= pageSize) {
    $wrapper.addClass(canLoadMoreClass)
  } else {
    $wrapper.removeClass(canLoadMoreClass)
  }
}

const handleDedicatedSearchLoadMore = e => {
  const $resultsWrapper = $('[data-dedicated-search-results]')
  const $resultsCounter = $('[data-dedicated-search-current]')
  const $wrapper = $(dedicatedSearchLoadMoreButton).closest(
    '[data-dedicated-search-load-more]',
  )
  const query = $wrapper.data('search-term')
  const pageSize = Number($wrapper.data('page-size'))
  const totalResults = Number($wrapper.data('total-results'))
  const currentPage = Number($wrapper.data('current-page'))
  const nextPage = currentPage + 1

  $.ajax({
    url: constructSearchUri(query, {
      mode: 'partial',
      page: nextPage,
      where: 'dedicatedsearch',
    }),
    type: 'GET',
    success(data) {
      if (!data) return

      $wrapper.data('current-page', nextPage)
      $resultsWrapper.append(data)
      const count = Math.min(pageSize * nextPage, totalResults)
      $resultsCounter.text(count)
      checkCanLoadMore()
    },
    error(res) {
      const errMsg = [res.status, res.statusText, res.responseText]
        .filter(Boolean)
        .join(', ')
      const err = new Error(`Dedicated seach error "${errMsg}"`)
      console.error(err)
    },
  })
}

openHeaderSearchButton &&
  openHeaderSearchButton.forEach(button =>
    button.addEventListener('click', openHeaderSearch),
  )

// Enable keyboard accessibility
openHeaderSearchButton &&
  openHeaderSearchButton.forEach(button =>
    button.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        e.preventDefault()
        openHeaderSearch()
      }
    }),
  )

sidebarSearchInput &&
  sidebarSearchInput.addEventListener('keyup', handleSidebarSearch)

closeHeaderSearchButton &&
  closeHeaderSearchButton.addEventListener('click', closeHeaderSearch)

headerSearchInput &&
  headerSearchInput.addEventListener('keyup', enterHeaderSearch)

headerSearchOverlay &&
  headerSearchOverlay.addEventListener('click', closeHeaderSearch)

document.body.addEventListener('keyup', escapeHeaderSearch)

sidebarSearchButton &&
  sidebarSearchButton.addEventListener('click', handleSidebarSearchButton)

headerSearchButton &&
  headerSearchButton.addEventListener('click', handleHeaderSearchButton)

dedicatedSearchInput &&
  dedicatedSearchInput.addEventListener('keyup', handleDedicatedSearch)

dedicatedSearchButton &&
  dedicatedSearchButton.addEventListener('click', handleDedicatedSearchButton)

dedicatedSearchLoadMoreButton &&
  dedicatedSearchLoadMoreButton.addEventListener(
    'click',
    handleDedicatedSearchLoadMore,
  )

$(function onDocumentReady() {
  checkCanLoadMore()
})
