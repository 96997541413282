const infobox = document.querySelectorAll('.fp-infobox')

infobox.forEach(item => {
  // Variables

  // Infobox variables
  const minus = item.querySelector('.fp-infobox__toggle--minus')
  const plus = item.querySelector('.fp-infobox__toggle--plus')
  const infoboxToggle = item.querySelector('.fp-infobox__toggle')
  const textContent = item.querySelector('.fp-infobox__text-content')
  const textHeight = textContent
    .querySelector('p')
    .getBoundingClientRect().height
  const dots = item.querySelector('.fp-infobox__dots')

  // Image variables
  const figure = item.querySelector('.fp-figure')
  const imageInfoHeight = item
    .querySelector('.fp-image-info')
    .getBoundingClientRect().height
  const figureHeight = item
    .querySelector('figure')
    .getBoundingClientRect().height
  const imageToggle = item.querySelector('.fp-figure__expandable--toggle')
  const imageSvg = imageToggle.querySelector('svg')

  const initialHeight = 205
  const initialPadding = 66.6666666667
  let infoboxIsExpanded = false
  let imageIsExpanded = false

  // Initials
  textContent.style.height = `${initialHeight}px`
  minus.style.display = 'none'
  if (figure) {
    figure.style.paddingBottom = `${initialPadding}%`
  }

  if (figure) {
    const img = figure.querySelector('img')
    if (
      img &&
      figure.getBoundingClientRect().height >=
        img.getBoundingClientRect().height
    ) {
      imageToggle.style.display = 'none'
      dots.textContent = ''
    }
  }

  if (item.classList.contains('fp-infobox--text-only')) {
    textContent.style.height = `${initialHeight / 2}px`
  }

  if (
    item.classList.contains('fp-infobox--text-only') &&
    textHeight <= initialHeight / 2
  ) {
    infoboxToggle.style.display = 'none'
    dots.style.display = 'none'
  }

  // Infobox expand function
  const toggleExpandInfobox = e => {
    infoboxIsExpanded = !infoboxIsExpanded

    if (infoboxIsExpanded) {
      plus.style.display = 'none'
      minus.style.display = 'block'
      textContent.style.height = `${
        figureHeight + imageInfoHeight + textHeight
      }px`
      dots.textContent = ''
    } else {
      minus.style.display = 'none'
      plus.style.display = 'block'
      textContent.style.height = `${initialHeight}px`
      dots.textContent = '...'
    }

    if (infoboxIsExpanded && item.classList.contains('fp-infobox--text-only')) {
      plus.style.display = 'none'
      minus.style.display = 'block'
      textContent.style.height = `${textHeight}px`
      dots.textContent = ''
    } else if (
      !infoboxIsExpanded &&
      item.classList.contains('fp-infobox--text-only')
    ) {
      minus.style.display = 'none'
      plus.style.display = 'block'
      textContent.style.height = `${initialHeight / 2}px`
      dots.textContent = '...'
    }
  }

  // Image expand function
  const toggleImageExpand = e => {
    imageIsExpanded = !imageIsExpanded

    if (imageIsExpanded) {
      const image = figure.querySelector('img')
      const imageHeight = Math.round(image.getBoundingClientRect().height)
      figure.style.transition = 'all 0.7s ease-in-out'
      figure.style.paddingBottom = '0px'
      figure.style.height = `${imageHeight}px`
      imageSvg.style.setProperty('transform', 'rotate(-180deg)')
    } else {
      figure.style.transition = 'all 0.7s ease-in-out'
      figure.style.paddingBottom = `${initialPadding}%`
      figure.style.height = '0px'
      imageSvg.style.setProperty('transform', 'rotate(0deg)')
    }
  }

  imageToggle.addEventListener('click', toggleImageExpand)
  infoboxToggle.addEventListener('click', toggleExpandInfobox)
})
