module.exports = {
  probeForTcfApi,
}

function probeForTcfApi(callback) {
  const probeConfig = {
    probeThreshold: 100,
    probeIntervall: 50,
    probeCount: 0,
  }
  const token = window.setInterval(() => {
    probeConfig.probeCount += 1
    if (probeConfig.probeCount > probeConfig.probeThreshold) {
      window.clearInterval(token)
    }

    if (!window.__tcfapi) return

    callback(null)
    window.clearInterval(token)
  }, probeConfig.probeIntervall)
}
