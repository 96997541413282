/**
 * --DISABLED--: This is not used currently, but the client asked us not to remove it
 * because we might need it in the future. It is disabled by not allowing the
 * editors to publish a promotional paywall layer via the LD editor.
 *
 * This will append a promotion paywall layer after the content is rendered,
 * and also it is not cached. Promotion paywall layers currently are:
 * footerbar_flyin, footerbar_light_flyin, headerbar_flyin, breakingbar, bubble
 * and overlay. These layers depend on the user's page / article visits and that's
 * why we execute this logic every time.
 * Please note that for this to apply, a cookie named 'pwlayout' must be present,
 * and it usually comes from varnish.
 */
const $ = require('jquery')

const { getCookie, removeCookie } = require('./helpers/helpers')

const paywallLayoutCookieName = 'pwlayout'

// This needs to be removed and the logic properly adjusted
// after a complete switch to paywall with cases
const paywallLayoutCaseDetectorString = 'case_'

const paywallLayoutCookie = getCookie(paywallLayoutCookieName)

$(function onDocumentReady() {
  if (paywallLayoutCookie) {
    const paywallLayoutCookieValue = paywallLayoutCookie.includes('&')
      ? paywallLayoutCookie.split('&')[0]
      : paywallLayoutCookie
    setupPaywallLayout(paywallLayoutCookieValue)
  }

  function setupPaywallLayout(paywallLayoutCookieValue) {
    removeCookie(paywallLayoutCookieName)
    const documentId = $('meta[name="documentid"]')?.attr('content')
    let paywallParam = ''
    if (paywallLayoutCookieValue) {
      if (
        paywallLayoutCookieValue.startsWith(paywallLayoutCaseDetectorString)
      ) {
        paywallParam = `paywallCase=${paywallLayoutCookieValue}&`
      } else {
        paywallParam = `layout=${paywallLayoutCookieValue}&`
      }
    }
    $.get(`/api/paywall/layout?${paywallParam}documentId=${documentId}`).then(
      response => {
        if (response && response.html) {
          const template = response.layout
          window.dataLayer.push({
            layer: template,
            event: 'LayerReady',
          })
          if (template.endsWith('flyin')) {
            $('body').append(response.html)
          } else if (template.endsWith('breakingbar')) {
            $('.fp-main-header__bottom').after(response.html)
          } else if (template.endsWith('bubble')) {
            $('.fp-header .fp-main-header__links').first().append(response.html)
          } else if (template.endsWith('overlay')) {
            $('body')
              .attr('scroll', 'no')
              .addClass('scrolling-disabled')
              .append(
                `<div class="paywall_overlay">${response.html}</div><div class="container-grayed-background"></div>`,
              )
          }
          setupCloseButton()
          setupToggleButton()
        }
      },
    )
  }

  const $articlePaywall = $('.fp-article-paywall')
  if ($articlePaywall.length > 0) {
    const layer = $articlePaywall.find('.fp-paywall-layer').data('layer')
    if (layer) {
      window.dataLayer.push({
        layer,
        event: 'LayerReady',
      })
    }
  }

  let bSHowAvFlyin = true
  $(document).on('scroll', () => {
    var y = $(this).scrollTop()
    if (y > 10 && bSHowAvFlyin) {
      $('.fp-paywall-layer-flyin--js').fadeIn()
    } else {
      $('.fp-paywall-layer-flyin--js').fadeOut()
    }
  })

  function setupCloseButton() {
    $('.fp-paywall-layer-flyin--closebutton').on('click', () => {
      bSHowAvFlyin = false
      $('.fp-paywall-layer-flyin--js').fadeOut()
    })
    $('.fp-paywall-layer-breakingbar--closebutton').on('click', () => {
      $('.fp-paywall-layer-breakingbar--js').fadeOut()
    })
    $('.fp-paywall-layer-bubble--closebutton').on('click', () => {
      $('.fp-paywall-layer-bubble--js').fadeOut()
    })
    $('.fp-paywall-layer-overlay--closebutton').on('click', () => {
      $('.paywall_overlay').fadeOut()
      $('.container-grayed-background').fadeOut()
      $('body').removeAttr('scroll').removeClass('scrolling-disabled')
    })
  }

  function setupToggleButton() {
    $('.fp-paywall-layer-flyin-light--button').on('click', () => {
      const flyinDialog = $('#fp-paywall-layer-light-flyin-wrapper')
      const arrow = $('.fp-paywall-layer-flyin-light--chevron-arrow path')
      const arrowPathDown = 'M18 9L12 15L6 9'
      const arrowPathUp = 'M6 15L12 9L18 15'
      const actionBtnOnCollapsed = $(
        '.fp-paywall-layer-light-flyin--action-link',
      )

      // If the paywall is collapsed then expand it
      if (flyinDialog.hasClass('collapsed')) {
        flyinDialog.removeClass('collapsed')
        arrow.attr('d', arrowPathDown)
        actionBtnOnCollapsed.hide()
      } else {
        // Else if it is expanded just collapse it
        flyinDialog.addClass('collapsed')
        arrow.attr('d', arrowPathUp)
        actionBtnOnCollapsed.show()
      }
    })
  }
})
