document.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById('cancel-subscription-form')
  if (!form) {
    return
  }

  const textAndPictures = document.getElementById('text-and-pictures')
  const formResult = document.getElementById('form-result')
  const savePageButton = document.getElementById('save-page-button')

  const showFakeSecondPage = () => {
    textAndPictures.style.display = 'none'
    form.style.display = 'none'
    formResult.style.display = 'block'
  }

  const setFormValuesInSecondPage = formValues => {
    Object.entries(formValues)
      .filter(([key]) => key !== 'channel')
      .forEach(([key, value]) => {
        const el = document.getElementById(`results-${key}`)
        if (el) {
          el.textContent = value
        }
      })

    // Set "sent date" using current time
    const currentDate = new Date()
    document.getElementById('results-current-date').textContent =
      currentDate.toLocaleDateString()
    document.getElementById('results-current-time').textContent =
      currentDate.toLocaleTimeString([], { timeStyle: 'short' })
  }

  form.addEventListener('submit', event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const formValues = Object.fromEntries(formData) // abonnement, email, titel etc.

    fetch('/api/public/cancel-subscription', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriberInfo: formValues,
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error()
        }
        showFakeSecondPage()
        setFormValuesInSecondPage(formValues)
      })
      .catch(err => {
        // An error occurred while sending the email. Please contact us at the provided phone number.
        alert(
          'Beim Senden der E-Mail ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns unter der angegebenen Telefonnummer.',
        )
      })
  })

  savePageButton.addEventListener('click', () => window.print())
})
