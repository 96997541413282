const { TCString } = require('@iabtcf/core')
const { probeForTcfApi } = require('./helpers/probe_for_tcf_api')

const ASC_TCF = {
  applyCustomConsentFromString,
  decodeString,
}
window.ASC_TCF = ASC_TCF

probeForTcfApi(() => {
  applyCustomConsentFromString()
})

function applyCustomConsentFromString() {
  const customTcString = window.ASC_TCF_STRING
  if (!customTcString) return

  const params = decodeString(customTcString)
  const handler = (data, success) => {
    if (success) {
      console.log('successfully applied custom tcf consent via string')
    } else {
      console.log('could not apply custom tcf consent via string', data)
    }
  }
  window.__tcfapi(
    'postCustomConsent',
    2,
    handler,
    params.vendorIds,
    params.purposeIds,
    params.legitimateInterestsPurposeIds,
  )
}

function decodeString(tcfString) {
  if (!tcfString) return tcfString

  const tcModel = TCString.decode(tcfString)

  const vendorIds = []
  const purposeIds = []
  const legitimateInterestsPurposeIds = []

  tcModel.vendorConsents.forEach((hasConsent, vendorId) => {
    if (hasConsent) {
      vendorIds.push(vendorId)
    }
  })

  tcModel.purposeConsents.forEach((hasConsent, purposeId) => {
    if (hasConsent) {
      purposeIds.push(purposeId)
    }
  })

  tcModel.purposeLegitimateInterests.forEach(
    (hasConsent, legitimatePurposeId) => {
      if (hasConsent) {
        legitimateInterestsPurposeIds.push(legitimatePurposeId)
      }
    },
  )

  return {
    vendorIds,
    purposeIds,
    legitimateInterestsPurposeIds,
  }
}
