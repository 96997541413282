document.addEventListener('DOMContentLoaded', () => {
  const EI_FIG = document.querySelectorAll('.fp-figure__expandable')

  EI_FIG.forEach(figure => {
    const EI_TOG = figure.querySelector('.fp-figure__expandable--toggle')
    let EI_ISEXPANDED = false

    const checkMainImageHeight = e => {
      const EI_IMG = figure.querySelector('img')
      if (EI_IMG.getBoundingClientRect().height <= 485) {
        figure.style.height = `${EI_IMG.getBoundingClientRect().height}px`
        figure.style.paddingBottom = '0'
        EI_TOG.style.display = 'none'
      }
    }

    document.addEventListener('DOMContentLoaded', checkMainImageHeight)

    const windowResize = () => {
      if (window.innerWidth <= 785) {
        const EI_IMG_M = figure.querySelector('img')
        const EI_IMG_HEI_M = Math.round(EI_IMG_M.getBoundingClientRect().height)
        figure.style.height = `${EI_IMG_HEI_M}px`
        figure.style.paddingBottom = '0'
      }
    }

    window.addEventListener('resize', windowResize)

    const toggleImageExpand = e => {
      const EI_IMG = figure.querySelector('img')
      const EI_IMG_HEI = Math.round(EI_IMG.getBoundingClientRect().height)
      const EI_SVG = EI_TOG.querySelector('svg')
      let EI_PAD
      if (window.innerWidth <= 1171) {
        EI_PAD = 378
      } else {
        EI_PAD = 485
      }

      e.stopPropagation()
      EI_ISEXPANDED = !EI_ISEXPANDED

      if (EI_ISEXPANDED) {
        figure.style.transition = 'all 0.7s ease-in-out'
        figure.style.height = `${EI_IMG_HEI}px`
        figure.style.paddingBottom = '0px'
        EI_SVG.style.setProperty('transform', 'rotate(-180deg)')
      } else {
        figure.style.transition = 'all 0.7s ease-in-out'
        figure.style.height = '0px'
        figure.style.paddingBottom = `${EI_PAD}px`
        EI_SVG.style.setProperty('transform', 'rotate(0deg)')
      }
    }

    EI_TOG.addEventListener('click', toggleImageExpand)
  })
})
