const _ = require('lodash')

const IGNORE_CLASS = 'js-add-id-ignore'
const ARTICLE_ID_ATTR = 'data-article-id'
const NON_INFERRABLE_ARTICLE_ID = -1

function addArticleId(context) {
  const { $ } = context

  const $articles = $(`article:not(.${IGNORE_CLASS})`)

  $articles.each((_, el) => {
    const $article = $(el)
    const articleId = $article.attr(ARTICLE_ID_ATTR)

    // Infer missing article ID using article's URL and
    // add it to its markup
    if (!articleId) {
      const inferredArticleId = inferArticleId(context, $article)

      if (inferredArticleId === NON_INFERRABLE_ARTICLE_ID) {
        return context
      }

      $article.attr(ARTICLE_ID_ATTR, inferredArticleId)
    }
  })

  return context
}

function inferArticleId(context, $article) {
  const { $ } = context

  const $parent = $article.parent('a')
  const $children = $article.children('a')
  let hasParentLink = false
  let hasChildLink = false
  let err = ''

  // Try and find a direct parent <a> for <article>
  if ($parent.length === 1) {
    hasParentLink = true
  }

  // Try and find a single direct <a> child for <article>
  if ($children.length === 1) {
    hasChildLink = true
  }

  if ($children.length > 1) {
    err = 'multiple <a> tags inside <article> tag.'
  } else if (hasParentLink && hasChildLink) {
    err = 'both direct parent/single child <a> tag exist.'
  } else if (!hasParentLink && !hasChildLink) {
    err = `no direct parent/single direct child that is a link.`
  }

  if (err) {
    // Extract article URL
    const {
      req: { url },
    } = context

    // FIXME: find a way to use the Winston logger (problems with webpack bundling)
    console.warn(`No way to infer article ID for '${url}': ${err}`)
    return NON_INFERRABLE_ARTICLE_ID
  }

  // Try to infer using parent <a> tag, otherwise use child <a>
  const $link = $(hasParentLink ? $parent[0] : $children[0])
  const href = $link.attr('href')

  // Article ID is unique and is always the last string after the '-'
  // at the end of said article's URL
  if (!href) {
    return NON_INFERRABLE_ARTICLE_ID
  }

  const inferredArticleId = parseInt(_.last(href.split('-')), 10)

  // Article IDs are unique and are integers
  if (!Number.isInteger(inferredArticleId)) {
    return NON_INFERRABLE_ARTICLE_ID
  }

  return inferredArticleId
}

module.exports = { addArticleId, ARTICLE_ID_ATTR }
